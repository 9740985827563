:root {
  --bg-color: #dfe5f5;

  --bg-color2: #645cfe;

  --accent: #01d2a1;
  --text-underline: #ffb619;
  --button-background: #0d59ef;

  --bg-color3: #eff3ff;

  --white: #fff;
  --darkaccent2: #a50f0e;
  --black: #1a1b20;
  --bs-gutter-x: 3rem;
  --accent2: #ea2316;
  --accent3: #ceeaed;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--black);
}

body {
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.4;
}
.h1,
h2 {
  font-size: 3rem;
}
.h3 {
  font-size: 2rem;
}
.h4 {
  font-size: 1.75rem;
}
.h5 {
  font-size: 1.75rem;
}
.h6 {
  font-size: 1.25rem;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }
}
.mainWrapper {
  header {
    padding: 1rem 0;
    display: none;

    @media (min-width: 992px) {
      padding: 1rem 0;
      display: none;
    }

    position: relative;
    z-index: 2;

    .logo-wrapper {
      a {
        img {
          max-width: 100%;
        }
      }
    }
  }
  .hero-section {
    padding: 2rem 0 0 0;
    position: relative;
    overflow: hidden;
    text-align: center;
    background: url(assets/bg.webp);
    background-size: cover;
    .container {
      padding: 0;
      position: relative;
      z-index: 2;

      @media (min-width: 992px) {
        max-width: 100%;
      }

      .row {
        min-height: 50vh;
        align-items: center;
        justify-content: center;

        @media (min-width: 992px) {
          align-content: flex-end;
        }

        .hero-container {
          padding: 0 1rem;
          border-radius: 8px;
          width: 100%;
          flex: 0 0 auto;
          @media (min-width: 992px) {
            width: 50%;
            text-align: left;
            padding-left: 3rem;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--accent);
              letter-spacing: -0.3px;
              text-transform: capitalize;
              border-radius: 30px;
            }
          }

          .sub-hero-section {
            .hero-section-wrapper {
              .hero-section-wrapper-content {
                h2 {
                  font-size: 30px;
                  line-height: 1.33;
                  margin-bottom: 1rem;
                  color: var(--black);

                  @media (min-width: 992px) {
                    font-size: 3rem;
                    margin-bottom: 1.5rem;
                  }
                }

                p {
                  font-size: 16px;
                  margin-bottom: 1rem;
                  color: var(--black);

                  @media (min-width: 992px) {
                    font-size: 20px;
                    margin-bottom: 1.5rem;
                  }
                }
              }

              .hero-sectiom-image {
                margin-bottom: 1rem;
              }

              .cta {
                text-align: center;
                @media (min-width: 992px) {
                  text-align: left;
                }
                a {
                  position: relative;
                  z-index: 0;
                  overflow: hidden;
                  border-radius: 10px;
                  padding-top: 13px;
                  padding-bottom: 13px;
                  padding-left: 40px;
                  padding-right: 40px;
                  font-size: 18px;
                  background: var(--button-background);
                  color: var(--white);
                  display: inline-block;
                  font-weight: 700;
                  line-height: 140%; /* 22.4px */
                  letter-spacing: 0.272px;
                  text-transform: uppercase;
                  &:hover,
                  &:focus {
                    background-color: var(--accent);
                  }
                }

                span {
                  margin-top: 0.5rem;
                  display: block;
                  font-weight: 500;
                  font-size: 1rem;
                }
              }
            }
          }
        }

        .card-container {
          margin-top: 2rem;
          position: relative;

          @media (min-width: 992px) {
            text-align: right;
            margin-top: 0;
            width: 50%;
            padding: 0;
          }
          .mainbg {
            width: 100%;
            position: relative;

            @media (min-width: 992px) {
              display: flex;
              justify-content: right;
            }
            img {
              position: relative;
              display: block;
            }
          }
        }
        .question-item {
          padding: 0 1rem;
          .congrats {
            max-width: 580px;
            width: 100%;
            background-color: var(--bg-color);
            padding: 1.25rem;

            @media (min-width: 992px) {
              padding: 2rem;
            }

            border-radius: 18px;
            text-align: center;

            h1 {
              font-size: calc(1.375rem + 1.5vw);
              line-height: 1.5;

              @media (min-width: 992px) {
                font-size: 2.5rem;
              }
            }

            h2 {
              color: #0058ff;
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              line-height: 120%;
            }

            a {
              display: block;
              margin: 1rem 0;
              background: var(--bg-color2);
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              transition: all 0.2s ease-in-out;

              &:hover,
              &:focus {
                background: var(--accent);
              }
            }

            p {
              span {
                color: #0058ff;
                font-weight: bold;
              }
            }

            img {
              margin-top: 1rem;
            }
          }
        }
        .question-wrapper {
          flex: auto;
          width: 100%;
          background-color: var(--bg-color);
          margin: 3rem 1rem;
          padding: 1.5rem;
          border-radius: 10px;

          @media (min-width: 992px) {
            padding: 2.5rem;
            max-width: 600px;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--accent);
              letter-spacing: -0.3px;
              text-transform: capitalize;
              text-align: center;
            }
          }

          .question-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .question {
              position: relative;
              text-align: center;

              @media (min-width: 992px) {
                padding: 0;
                background-color: transparent;
                box-shadow: none;
                margin: 0;
              }

              border-radius: 18px;
              margin: 1rem auto;
              max-width: 580px;
              width: 100%;

              h2 {
                font-size: 25px;
                font-weight: 700;

                @media (min-width: 1200px) {
                  font-size: 2rem;
                }
              }

              a {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1rem 0;
                background: var(--bg-color2);
                color: #fff;
                font-weight: bold;
                text-decoration: none;
                padding: 0 1rem;
                font-size: 18px;
                border-radius: 8px;
                box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
                height: 50px;
              }
              .option-renderer-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                margin-top: 2rem;
                .options-container {
                  width: 100%;
                  input {
                    display: none;
                  }

                  .option-button {
                    width: 100%;
                    color: #fff;
                    outline: none;
                    border: none;
                    display: flex;
                    align-items: center;
                    padding: 16px 32px;
                    font-size: 18px;
                    border-radius: 4px;
                    cursor: pointer;
                    justify-content: center;
                    background: var(--bg-color2);
                    text-align: center;
                    user-select: none;
                    font-weight: bold;
                  }
                }
              }
            }
          }

          h1 {
            color: #fff;
          }
        }
      }
    }

    .question-item {
      padding: 0 1rem;
      .congrats {
        max-width: 580px;
        width: 100%;
        background-color: var(--bg-color);
        padding: 1.25rem;

        @media (min-width: 992px) {
          padding: 2rem;
        }

        border-radius: 18px;
        text-align: center;

        h1 {
          font-size: calc(1.375rem + 1.5vw);
          line-height: 1.5;

          @media (min-width: 992px) {
            font-size: 2.5rem;
          }
        }

        h2 {
          color: #0058ff;
          margin-bottom: 0.5rem;
          font-size: 1.4rem;
          line-height: 120%;
        }

        a {
          display: block;
          margin: 1rem 0;
          background: var(--bg-color2);
          color: #fff;
          font-weight: bold;
          text-decoration: none;
          padding: 1rem;
          font-size: 1.5rem;
          border-radius: 8px;
          box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
          transition: all 0.2s ease-in-out;

          &:hover,
          &:focus {
            background: var(--accent);
          }
        }

        p {
          span {
            color: #0058ff;
            font-weight: bold;
          }
        }

        img {
          margin-top: 1rem;
        }
      }
    }
    .question-wrapper {
      flex: auto;
      width: 100%;
      background-color: var(--bg-color);
      margin: 0 1rem;
      padding: 1.5rem;
      border-radius: 10px;

      @media (min-width: 992px) {
        margin: 0;
        padding: 2.5rem;
        max-width: 600px;
      }

      .headline-wrapper {
        h1 {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 10px;
          color: var(--accent);
          letter-spacing: -0.3px;
          text-transform: capitalize;
          text-align: center;
        }
      }

      .question-item {
        .question {
          position: relative;
          text-align: center;

          @media (min-width: 992px) {
            padding: 0;
            background-color: transparent;
            box-shadow: none;
            margin: 0;
          }

          border-radius: 18px;
          margin: 1rem auto;
          max-width: 580px;
          width: 100%;

          h2 {
            font-size: 25px;
            font-weight: 700;

            @media (min-width: 1200px) {
              font-size: 2rem;
            }
          }

          a {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem 0;
            background: var(--bg-color2);
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            padding: 0 1rem;
            font-size: 18px;
            border-radius: 8px;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            height: 50px;
          }
        }
      }

      h1 {
        color: #fff;
      }
    }
  }
  .card-section {
    position: relative;
    z-index: 5;
    padding: 1rem 0;
    background: var(--bg-color);
    backdrop-filter: blur(15px);

    .container {
      .row {
        justify-content: center;

        .custom-container {
          width: 100%;

          .icon-pack {
            margin-top: 1rem;
            @media (min-width: 992px) {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            }
            .headline-back {
              text-align: center;
              flex: 0 0 auto;
              width: 100%;
              h2 {
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: 2px;
                color: #103040;
              }
            }

            .logo-icon-pack {
              max-width: 38.75rem;
              margin: 1rem auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              gap: 0.5rem;

              @media (min-width: 992px) {
                width: 100%;
                margin: 0;
                flex: 0 0 auto;
                max-width: 100%;
                margin-top: 2rem;
              }

              img {
                flex: 0 0 auto;
                width: 30%;
                margin-bottom: 0.25rem;

                @media (min-width: 992px) {
                  width: 10%;
                }
              }
            }
          }
        }
      }
    }
  }

  .section1 {
    padding: 3rem 0;
    background: #f0f6fb;
    @media (min-width: 992px) {
      padding: 4rem 0;
    }

    .container {
      .row {
        align-items: center;
        justify-content: center;

        .section-1-content {
          flex: 0 0 auto;
          width: 100%;
          margin: 0 auto;

          @media (min-width: 992px) {
            width: 50%;
            padding: 0 1rem;
            margin: 2rem auto;
            text-align: center;
          }

          h1 {
            margin-bottom: 1rem;
            line-height: 140%;
            font-size: 24px;
            letter-spacing: 0.036px;
            @media (min-width: 992px) {
              font-size: 2.5rem;
            }
          }
          p {
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.08px;
          }
        }

        .cta-holder {
          max-width: 580px;
          margin: 1rem 0;

          a {
            position: relative;
            z-index: 0;
            overflow: hidden;
            border-radius: 10px;
            padding-top: 13px;
            padding-bottom: 13px;
            padding-left: 40px;
            padding-right: 40px;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.5rem;
            letter-spacing: -0.3px;
            background: var(--bg-color2);
            color: var(--white);
            display: inline-block;

            &:hover,
            &:focus {
              background-color: var(--accent);
            }
          }
          span {
            display: block;
            font-size: 14px;
          }
        }

        .item-benefits {
          padding: 1rem 0;
          width: 100%;

          @media (min-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (max-width: 600px) {
            grid-template-columns: 1fr;
          }
          margin: auto;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 1rem;
          overflow: hidden;
          .card {
            position: relative;
            border-radius: 5px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
            @media (max-width: 350px) {
              min-height: 250px;
            }
            img {
              width: 100%;
              height: auto;
              border-radius: 5px 5px 0 0;
              object-fit: cover;

              @media (max-width: 350px) {
                height: 100%;
                min-height: 100%;
              }
            }
            .card-content {
              padding: 10px;
              display: flex;
              flex-wrap: wrap;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: 1.4rem 1rem;
              background: var(--white);
              border-radius: 10px;
              @media (min-width: 992px) {
                bottom: 2rem;
                margin: 0rem 3rem;
                padding: 20px;
              }
              @media (max-width: 350px) {
                bottom: 9px;
              }
              .benefitImage {
                flex: 0 0 auto;
                position: relative;
                margin-bottom: 1.5rem;
                display: inline-block;
                padding: 10px;
                background: var(--bg-color2);
                border-radius: 100px;
                img {
                  max-width: 30px;
                  vertical-align: middle;
                }
              }
              .benefitHeadline {
                h3 {
                  text-align: left;
                  font-size: 13px;
                  color: #042552;
                  padding-bottom: 1rem;
                  @media (min-width: 992px) {
                    font-size: 16px;
                  }
                }
                p {
                  font-size: 11px;
                  color: #042552;
                  @media (min-width: 992px) {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .section2 {
    padding: 3rem 0;
    background: var(--bg-color2);
    @media (min-width: 992px) {
      padding: 5rem 0;
    }
    .container {
      .row {
        .section2-headline-container {
          width: 100%;
          text-align: center;
          @media (min-width: 992px) {
            width: 60%;
            margin: 0 auto 2rem auto;
          }
          h1 {
            margin-bottom: 1rem;
            font-size: 24px;
            font-weight: 700;
            line-height: 140%; /* 33.6px */
            letter-spacing: 0.036px;
            color: var(--white);
            @media (min-width: 992px) {
              font-size: 3rem;
            }
          }

          p {
            margin-bottom: 2rem;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: 0.08px;
            color: var(--white);
          }
        }

        .section2-stat-counter {
          width: 100%;
          background: var(--white);
          padding: 48px;
          text-align: center;
          gap: 78px;
          border-radius: 10px;
          @media (min-width: 992px) {
            display: flex;
          }

          .statCounter {
            margin-bottom: 1.5rem;

            @media (min-width: 992px) {
              .statCounter {
                width: 25%;
              }
            }

            .statCounterStats {
              h3 {
                color: var(--bg-color2);

                font-size: 24px;
              }

              span {
                font-size: 20px;
                font-weight: 600;
              }
            }
            .statCounterIntro {
              p {
                padding-top: 1rem;
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
  .testimonial {
    padding: 3rem 0;
    text-align: center;
    background: var(--bg-color3);
    @media (min-width: 992px) {
      padding: 4rem 0;
    }
    .container {
      border-radius: 25px;
      .row {
        img {
          max-width: 100%;

          @media (min-width: 992px) {
            width: 50%;
          }
        }

        .testimonial-headline {
          flex: 0 0 auto;
          width: 100%;
          @media (min-width: 992px) {
            width: 40%;
            text-align: left;
          }
          h1 {
            margin-bottom: 1rem;
            line-height: 120%;
            font-size: 24px;
            font-weight: 700;
            line-height: 140%; /* 33.6px */
            letter-spacing: 0.036px;
            color: var(--black);
            @media (min-width: 1200px) {
              font-size: 2.5rem;
            }
          }

          .testimonial-content-holder {
            flex: 0 0 auto;
            width: 100%;

            h3 {
              font-weight: 500;
              color: var(--black);
            }

            p {
              margin-bottom: 1rem;
              color: var(--black);
              @media (min-width: 992px) {
                width: 70%;
              }
            }
          }
        }

        .testimonial-list {
          display: flex;
          margin-top: 2rem;
          flex-wrap: wrap;
          @media (min-width: 992px) {
            width: 60%;
          }
          .testimonial-item {
            margin-bottom: 1rem;
            flex: 0 0 auto;
            width: 100%;
            margin-bottom: 1.5rem;

            @media (min-width: 992px) {
              width: 50%;
              padding: 0 1rem;
            }

            .testimonial-inner {
              padding: 24px;
              text-align: left;
              border-radius: 10px;
              background-color: var(--white);

              .userimg {
                max-width: 38px;
                border-radius: 100%;
              }

              .testimonial-top {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;

                &:nth-of-type(1) {
                  margin-bottom: 1rem;
                }

                .user {
                  h3 {
                    font-size: 15px;
                  }
                }
                .star-rating {
                  img {
                    width: 100%;
                  }
                }
              }
              .testimonial-bottom {
                margin-bottom: 16px;
                p {
                  font-size: 15px;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
  }

  .section3 {
    padding: 3rem 0;
    background: var(--bg-color3);
    align-items: center;
    .container {
      .row {
        display: block;
        align-items: center;
        justify-content: center;

        .faq {
          flex: 0 0 auto;
          width: 100%;
          text-align: center;

          @media (min-width: 992px) {
            width: 50%;
            padding: 0 1rem;
            margin: 0 auto;
          }

          h2 {
            font-size: 2rem;
            font-weight: 800;
            line-height: 50px;
            letter-spacing: 0.003em;
            margin-bottom: 8px;
          }
        }

        .accordion {
          margin-top: 50px;
          cursor: pointer;
          @media (min-width: 992px) {
            width: 81%;
            margin: 4rem auto;
          }
          .accordion-list {
            margin-bottom: 18px;
            padding: 16px 20px;
            box-sizing: border-box;
            border-bottom: 1px solid #e1e4ed;
            background: var(--white);

            .accordion-title {
              display: flex;
              justify-content: space-between;
              .question {
                font-size: 1rem;
                font-weight: 600;
              }
            }
          }

          .accordion-list.active {
            background-color: var(--bg-color2);
            .question {
              color: #02f597;
            }
          }

          .icon {
            margin-right: 14px;
            padding: 2px 4px;
            position: relative;
            margin-bottom: 5px 5px;
            display: inline-block;

            border-radius: 8px;
          }

          .answer {
            margin-top: 10px;
            color: var(--white);
          }
        }
      }
    }
  }

  .footer {
    padding: 4rem 0 6rem 0;
    background-color: var(--bg-color2);
    .top-footer {
      p {
        font-size: 0.875rem;
        color: var(--white);
      }
    }

    .bottom-footer {
      .footer-navigation {
        flex: 0 0 auto;
        width: 100%;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @media (min-width: 992px) {
          justify-content: space-between;
        }

        .footer-copyright {
          flex: 0 0 auto;
          width: 100%;
          p {
            color: var(--white);
          }
          @media (min-width: 992px) {
            width: auto;
          }
        }

        .footer-navigation-wrapper {
          flex: 0 0 auto;
          width: 100%;

          @media (min-width: 992px) {
            width: auto;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
              color: var(--white);

              &:nth-child(1) {
                &::after {
                  content: "|";
                  margin: 0 1rem;
                  color: var(--white);
                }
              }

              a {
                text-decoration: none;
                color: var(--white);
              }
            }
          }
        }
      }
    }
  }

  .policy-wrapper {
    .policy-title {
      padding: 2rem 0;

      h1 {
        font-size: calc(1.375rem + 1.5vw);

        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }

      flex: 0 0 auto;
      width: 100%;
      border-bottom: 1px solid gray;
      margin-bottom: 2rem;
    }

    .policy-paragraph {
      padding-bottom: 4rem;

      p {
        margin-bottom: 0.5rem;
      }

      h2 {
        font-size: calc(1.325rem + 0.9vw);
        margin: 1rem 0;

        @media (min-width: 1200px) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: calc(1.3rem + 0.6vw);
        margin-bottom: 1rem;

        @media (min-width: 1200px) {
          font-size: 1.75rem;
        }
      }

      h3 {
        font-size: calc(1.275rem + 0.3vw);

        @media (min-width: 1200px) {
          font-size: 1.5rem;
        }
      }

      ul {
        list-style-type: disc;
        margin-bottom: 0.5rem;
      }
    }
  }
}
